<template>
    <div class="widget__item widget__testing dashboard__block shadow-none">
        <div class="testing__header">
            <h2 class="h2 testing__title">Тестирование</h2>
        </div>

        <!-- Результаты тестирования -->
        <div class="testing__body" v-if="data.configured">
            <vue-easy-pie-chart class="js-pie-chart-vue testing__result" :size="194" :scale-length="0" :line-width="11"
                                bar-color="#fcc142" track-color="#e9e9e9" :percent="percent">
                <span class="testing__result-body">
                    <div class="testing__result-count">
                        <span class="testing__result-ready">{{data.admin_info.count_ready}}</span>
                        из
                        <span class="testing__result-total ">{{data.admin_info.total}}</span>
                    </div>
                    <span class="testing__desc">сотрудников <br/> готовы к работе</span>
                </span>
            </vue-easy-pie-chart>
        </div>
        <!-- Тестирование не настроено -->
        <div class="testing__body" v-if="!data.configured">
            <img width="60" height="60" src="~assets/img/favicons/doc_not_ready.svg" alt="" class="testing__angle-icon">
            <span class="testing__text">Тестирование не настроено</span>
        </div>

         <div class="testing__footer" >
            <router-link v-if="data.configured" to="/testing/results/" class="testing__link">
                Результаты тестирования
            </router-link>
        </div>

    </div>
</template>

<script>
    import session from '@/api/session';
    import VueEasyPieChart from 'vue-easy-pie-chart'
    import 'vue-easy-pie-chart/dist/vue-easy-pie-chart.css'

    export default {
        name: 'TestStateWidget',
        components:{ VueEasyPieChart },
        data() {
            return {
                days: '00',
                hours: '00',
                minutes: '00',
                seconds: '00',
                percent: 0,
                attempts: 0,
                data: {
                    configured: true,
                    admin_info: {
                        count_ready: 0,
                        total: 0
                    }
                }
            }
        },
        created: function () {
            this.loadData();
        },
        methods: {
            loadData() {
                session
                .get('/api/v1/dashboard-widget/testing/')
                .then(response => {
                    this.data = response.data;
                    if (this.data.configured) {
                        this.percent = 100 * this.data.admin_info.count_ready / this.data.admin_info.total;
                    }
                })
                .catch(() => {
                })
            },
        }
    }
</script>

<style lang="sass" scoped>
    @import "#sass/v-style"


    .inner-text
        display: flex !important
        justify-content: center !important

    .widget__testing
        height: 380px
        display: flex
        flex-direction: column
        align-items: center
        justify-content: space-between

        .testing
            &__text
                font-size: 1rem
                text-align: center
                margin: 0 auto

            &__desc
                font-size: 1rem
                line-height: 18px

            &__header
                display: flex
                width: 100%
                justify-content: space-between
                align-items: center

            &__icon
                font-size: 1.25rem
                color: #e0e3f0

                &:hover
                    color: $gold

            &__body
                display: flex
                flex-direction: column
                align-items: center

            &__result-body,
            &__timer-body
                display: flex
                flex-direction: column
                text-align: center
                justify-content: center
                line-height: 30px
                height: 100%

            &__result-count
                font:
                    size: 18px
                    weight: 600

            &__result-ready,
            &__time
                font:
                    size: 32px
                    weight: 600

            &__doc-icon,
            &__angle-icon
                height: 60px
                width: 60px
                margin-bottom: 15px

            &__link
                color: $link-main
                font-size: 1rem

            &__hide
                font-size: 1rem
                color: #AAAAAA

                &-icon
                    margin-right: 8px
                    font-size: 1.125rem

                &:hover
                    color: #aaaaaa
</style>
